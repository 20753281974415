import crypto from 'crypto-js'
import '../../public/assets/global'

const makeMD5 = msg => crypto.MD5(msg).toString()
const formatUploadURL = (relUrl/*相对或绝对地址*/) => {
    relUrl = relUrl || ''
    if (relUrl.startsWith('http://') || relUrl.startsWith('https://') || relUrl.startsWith('//')) {
        return relUrl
    } else {
        return window.BASE_URL + relUrl
    }
}

export {
    makeMD5,
    formatUploadURL
}