<template>
    <div class="news-page">
        <div class="bannar">
            <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
            <img :src="bannerSrc" v-if="bannerType">
            <video v-else muted="muted" id="startvideo" class="video" :src="bannerSrc" type="video/mp4"  autoplay="autoplay" controls="controls" loop="-1">
                <p>你的浏览器不支持video标签.</p>
            </video>
            <div class="overlay"></div>
            <div class="bannar-info">
                <!-- <div class="bannar-title">天府国际赛道</div>
                <div class="bannar-detail">{{bannerTitle}}</div> -->
                <div class="banner-content">
                    <div class="bannar-title">天府国际赛道</div>
                    <div class="bannar-detail">{{bannerTitle}}</div>
                </div>
                <img class="banner-img" src="../../image/top/banner4.png">
            </div>
        </div>
        <main>
            <div style="textAlign:center">
                <div class="title">天府国际赛道</div>
                <!-- <img :src="info.src" style="width:100%"> -->
            </div>
            <div v-html="info.content" style="textAlign:center" class="details-content"></div>
           
        </main>
        <!-- <bottom-box></bottom-box> -->
    </div>
</template>
<script>
import {getBanner,getOur} from '../../api/homeApi'
export default {
    data() {
        return {
            bannerSrc: '',
            bannerType: 1, //1.图片 0.视频
            bannerTitle: '',
            newsList: [],
            info: {},
        }
    },
    created() {
        this.id = this.$route.params.id
        this.getBanner().then(() => {
            let myvideo = document.getElementById('startvideo');
            let bgBox = document.getElementsByClassName('banner-img')[0];
            if(myvideo) {
                myvideo.addEventListener('timeupdate', () => {
                    var playTime
                    playTime = Math.floor(myvideo.currentTime)
                    if(playTime >= Math.floor(myvideo.duration) ) {
                        bgBox.style.opacity = 0.6
                        bgBox.style.transition = ''
                    }else {
                        if(playTime == 0) {
                            if(!bgBox.style.transition) {
                                bgBox.style.opacity = 0
                                bgBox.style.transition = '4s all'
                            }
                        }
                    }
                });
                
            }
        })
        this.getInfo()
    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(
            /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
            );
            return flag;
        },
        async getBanner() {
            await getBanner({
                plate: 0,
            }).then(res => {
                if(res.code == 0) {
                    if(res.infos.length > 0) {
                        if(this._isMobile()) {
                            this.bannerType = 1
                            this.bannerSrc = JSON.parse(res.infos[0].mobileImage)[0].url
                        }else {
                            let arr = JSON.parse(res.infos[0].image)
                            if([
                                'image/png',    // .png
                                'image/jpeg',   // .jpg .jpeg
                                'image/x-ms-bmp', // .bmp
                                'image/gif',    // .gif
                                'image/tiff',   // .tif .tiff
                            ].includes(arr[0].type)) {
                                this.bannerType = 1
                            }else {
                                this.bannerType = 0
                            }
                            this.bannerSrc = JSON.parse(res.infos[0].image)[0].url
                        }
                        this.bannerTitle = res.infos[0].content
                    }
                }
            })
        },
        // 获取咨询
        getInfo() {
            getOur().then(res => {
                if(res.code == 0) {
                    this.info = res.info
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>

    .news-page {
        background: url('../../image/home/bg.jpg') center center no-repeat;
        background-size: 100%;
        .bannar {
            width: 100%;
            height: 100%;
            // height: 5.5rem;
            background: #666;
            position: relative;
            img {
                vertical-align: middle;
                height: calc((725/1920) * 100vw);
                min-height: 500px;
                max-height: 725px;
                width: 100%;
            }
            .bannar-info {
                position: absolute;
                top: 0%;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                z-index: 9;
                .banner-img {
                    height: 100%;
                    // width: 1820px;
                    position: absolute;
                    padding-right: 100px;
                    top: 0;
                    left: 0;
                    opacity: .63;
                }
                .banner-content {
                    position: absolute;
                    z-index: 9;
                    padding-left: 90px;
                    top: 55%;
                    text-align: left;
                }
                .bannar-title {
                    font-size: 42px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .bannar-detail {
                    font-size: 28px;
                    color: #fff;
                    // cursor: pointer;
                }
            }
        }
        main {
            box-sizing: border-box;
            width: 1200px;
            padding: .8rem 0rem;
            // background-color: #fff;
            margin: 0 auto;
            .title {
                font-size: 32px;
                margin-bottom: 30px;
            }
        }
    }
 /deep/.details-content {
        p{
            span {
                color: #666 !important;
            }
        }
    }    
</style>