<template>
    <div class="news-page">
        <!-- <top-box :option="3"></top-box> -->
        <div class="bannar">
           <!-- <img src="../../image/bg.png"> -->
            <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
            <img :src="bannerSrc" v-if="bannerType">
            <video v-else muted="muted" id="startvideo" class="video" :src="bannerSrc" type="video/mp4"  autoplay="autoplay" controls="controls" loop="-1">
                <p>你的浏览器不支持video标签.</p>
            </video>
            <div class="overlay"></div>
            <div class="bannar-info">
                <!-- <div class="bannar-title">新闻资讯</div>
                <div class="bannar-detail">{{bannerTitle}}</div> -->
                <div class="banner-content">
                    <div class="bannar-title">新闻资讯</div>
                    <div class="bannar-detail">{{bannerTitle}}</div>
                </div>
                <img class="banner-img" src="../../image/top/banner4.png">
            </div>
        </div>
        <main>
            <!-- <div class="main-option">
                <div @click="optionClick(index)" v-for="(item,index) in optionList" :key="index" :class="optionIdx == index ? 'main-option-item active' : 'main-option-item'">
                    {{item.type}}
                </div>
            </div> -->
            <!--  -->
            <div class="main-search">
                搜索内容：“{{this.searchValue}}”
            </div>
            <div class="main-content">
                <div v-for="(item,index) in newsList" :key="index" class="main-content-item" @click="detailsClick(item.id)">
                    <div class="main-content-item-img">
                        <img :src="item.src" :title="item.title">
                    </div>
                    <div class="info">
                        <div class="title">{{item.title}}</div>
                        <div class="content" v-html="item.detail">
                        </div>
                        <div class="time">{{item.releaseTime}}</div>
                    </div>
                </div>
                <div class="empty" v-if="newsList.length == 0">
                    <div class="empty-bg"></div>
                    <div class="empty-content">暂无数据~</div>
                </div>
            </div>
            <div class="pagination" v-if="newsList.length > 0">
                 <el-pagination
                    background
                    :page-size="4"
                    layout="prev, pager, next"
                    @current-change="currentChange"
                    :total="total">
                </el-pagination>
            </div>
        </main>
    </div>
</template>
<script>
import {getBanner,getNew,getCategory} from '../../api/homeApi'
export default {
    data() {
        return {
            optionIdx: 0,
            optionList: ['最新发布','公司新闻','行业动态'],
            bannerSrc: '',
            bannerType: 1, //1.图片 0.视频
            bannerTitle: '',
            newsList: [],
            total: 0,
            pageNum: 1,
            type: '',
            searchValue: '',
        }
    },
    created() {
        this.searchValue = this.$route.query.value
        this.getBanner().then(() => {
            let myvideo = document.getElementById('startvideo');
            let bgBox = document.getElementsByClassName('banner-img')[0];
            if(myvideo) {
                myvideo.addEventListener('timeupdate', () => {
                    var playTime
                    playTime = Math.floor(myvideo.currentTime)
                    if(playTime >= Math.floor(myvideo.duration) ) {
                        bgBox.style.opacity = 0.6
                        bgBox.style.transition = ''
                    }else {
                        if(playTime == 0) {
                            if(!bgBox.style.transition) {
                                bgBox.style.opacity = 0
                                bgBox.style.transition = '4s all'
                            }
                        }
                    }
                });
                
            }
        })
        this.getCategory()
    },
    watch: {
        "$route.query.value": {
            handler(val) {
                this.searchValue = val
                this.getCategory()
            }
        },
    },
    methods: {
        optionClick(val) {
            this.optionIdx = val
            this.type = this.optionList[val].id
            this.getNew()
        },
        _isMobile() {
            let flag = navigator.userAgent.match(
            /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
            );
            return flag;
        },
        async getBanner() {
            await getBanner({
                plate: 3,
            }).then(res => {
                if(res.code == 0) {
                    if(res.infos.length > 0) {
                        if(this._isMobile()) {
                            this.bannerType = 1
                            this.bannerSrc = JSON.parse(res.infos[0].mobileImage)[0].url
                        }else {
                            let arr = JSON.parse(res.infos[0].image)
                            if([
                                'image/png',    // .png
                                'image/jpeg',   // .jpg .jpeg
                                'image/x-ms-bmp', // .bmp
                                'image/gif',    // .gif
                                'image/tiff',   // .tif .tiff
                            ].includes(arr[0].type)) {
                                this.bannerType = 1
                            }else {
                                this.bannerType = 0
                            }
                            this.bannerSrc = JSON.parse(res.infos[0].image)[0].url
                        }
                        this.bannerTitle = res.infos[0].content
                    }
                }
            })
        },
        // 获取咨询
        getNew() {
            getNew({
                limit: 4,
                page: this.pageNum,
                key: this.searchValue
            }).then(res => {
                if(res.code == 0) {
                    this.total = res.page.totalCount
                    this.newsList = res.page.list.map(item => {
                        let str = item.content.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "")
                        var replaceString = '<span style="color: ' + '#EE6A23 !important' + ';">' + this.searchValue + "</span>";
                        str = str.replace(RegExp(this.searchValue,'g') ,replaceString);
                        item.src = JSON.parse(item.image)[0].url
                        item.detail = str
                        return item
                    })
                }
            })
        },
        currentChange(val) {
            this.pageNum = val
            this.getNew()
        },
        detailsClick(id) {
            this.$router.push({path: `/news-details/${id}`})
        },
        getCategory() {
            getCategory().then(res => {
                if(res.code == 0) {
                    this.optionList = res.infos
                    this.type = this.optionList[0].id
                    this.getNew()
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>

    .news-page {
        background: url('../../image/home/bg.jpg') center center no-repeat;
        .bannar {
            width: 100%;
            height: 100%;
            // height: 5.5rem;
            background: #666;
            position: relative;
            img {
                vertical-align: middle;
                height: calc((725/1920) * 100vw);
                min-height: 500px;
                max-height: 725px;
                width: 100%;
            }
            .bannar-info {
                position: absolute;
                top: 0%;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                z-index: 9;
                .banner-img {
                    height: 100%;
                    // width: 1820px;
                    position: absolute;
                    // padding-right: 100px;
                    top: 0;
                    left: -360px;
                    opacity: .63;
                }
                .banner-content {
                    position: absolute;
                    z-index: 9;
                    padding-left: 90px;
                    top: 55%;
                    text-align: left;
                }
                .bannar-title {
                    font-size: 42px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .bannar-detail {
                    font-size: 28px;
                    color: #fff;
                    // cursor: pointer;
                }
            }
        }
        main {
            box-sizing: border-box;
            width: 1600px;
            padding: .8rem 0rem;
            // background-color: #fff;
            margin: 0 auto;
            .main-option {
                display: flex;
                justify-content: center;
                margin-bottom: .6rem;
                .main-option-item:nth-last-child(1) {
                    margin-right: 0;
                }
                .main-option-item {
                    width: 1.8rem;
                    height: .5rem;
                    background-color: #F1F1F1;
                    font-size: .18rem;
                    color: #666;
                    text-align: center;
                    line-height: .5rem;
                    margin-right: .6rem;
                    cursor: pointer;
                    border-radius: .04rem;
                }
                .active {
                    background-color: #EE6A23;
                    color: #fff;
                }
            }
            .main-content {
                .main-content-item:nth-last-child(1) {
                    margin-bottom: 0;
                }
                .main-content-item:hover {
                    .info {
                        background-color: #f1f1f1;
                    }
                }
                .main-content-item {
                    width: 100%;
                    display: flex;
                    margin-bottom: .4rem;
                    cursor: pointer;
                    box-shadow: 1px 1px 10px #ccc;
                    .main-content-item-img {
                        width: 4.3rem;
                        height: 2.5rem;
                        display: block;
                        flex-shrink: 0;
                        overflow: hidden;
                        img {
                            width: 4.3rem;
                            height: 2.5rem;
                            display: block;
                            transition: .5s all;
                        }
                        img:hover {
                            transition: .5s all;
                            transform: scale(1.1);
                        }
                    }
                    
                    .info {
                        box-sizing: border-box;
                        padding: .3rem .4rem;
                        border: 1px solid #E6E6E6;
                        .title {
                            font-size: 22px;
                            color: #333;
                            margin-bottom: .1rem;
                            font-weight: bold;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        /deep/.content {
                            font-size: .14rem;
                            line-height: 2;
                            display: -webkit-box;
                            -webkit-line-clamp:4;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            color: #666;
                            margin-bottom: .1rem;
                            height: 110px;
                            p {
                                span {
                                    color: #666 !important;
                                    font-size: .18rem !important;
                                }
                            }
                        }
                        .time {
                            font-size: .16rem;
                            color: #666;
                        }
                    }
                }
            }
            /deep/.pagination {
                margin-top: 40px;
                text-align: center;
                .el-pagination.is-background .el-pager li:not(.disabled).active {
                    background-color: #EE6A23;
                }
                .el-pagination.is-background .el-pager li:not(.disabled):hover {
                    color: #EE6A23;
                }
            }
        }
    }
.main-search {
    font-size: 28px;
    margin-bottom: 40px;
}  
</style>