module.exports = {
    language: {
      name: '中文',
    },
    menu: {
      menu0: '首页',
      menu1: '赛道探索',
      menu2: '赛事活动',
      menu3: '合作开发',
      menu4: '新闻资讯',
      menu5: '娱乐体验',
      menu6: '联系我们',
    },
    home: {
      activity: '赛事活动',
      news: '新闻资讯',
      project: '项目介绍',
      business: '业务介绍',
      brand: '品牌合作',
      ours: '关于我们',
      details: '了解详情',
      more: '了解更多'
    },
    bottom: {
      
    },
};