;(function(w) {
    // w.BASE_URL = '/apis'
    // w.BASE_URL = 'http://192.168.0.10:8804/api'
    w.BASE_URL = 'https://saidao.cdls666.com/api'
    // w.BASE_URL = 'http://www.cdcircuit.com/api'
})(window);

// function a(w) {

// }
// a(window)