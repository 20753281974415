<template>
    <div class="home-page">
        <div class="bannar">
            <!-- <img src="../../image/bg.png"> -->
            <img :src="bannerSrc" v-if="bannerType">
            <video v-else-if="!bannerType" muted="muted" id="startvideo" class="video" :src="bannerSrc" type="video/mp4"  autoplay="autoplay" controls="controls" loop="-1">
            <!-- <video v-else-if="!bannerType" muted="muted" id="startvideo" class="video" autoplay="autoplay" controls="controls" loop="-1"> -->
                <p>你的浏览器不支持video标签.</p>
                <!-- <source :src="bannerSrc" type="video/mp4" /> -->
            </video>
            <!-- <video-player v-else-if="!bannerType" :videoUrl="bannerSrc"></video-player> -->
            <div class="overlay"></div>
            <div class="bannar-info">
                <!-- <div class="bannar-title">天府国际赛道</div>
                <div class="bannar-detail">{{bannerTitle}}</div>
                <img class="banner-img" src="../../image/top/banner.png"> -->
                <div class="banner-content">
                    <div class="bannar-title">天府国际赛道</div>
                    <div class="bannar-detail">{{bannerTitle}}</div>
                </div>
                <img id="banner-img" class="banner-img" src="../../image/top/banner4.png">
            </div>
        </div>
        <main>
            <div class="activity-bg">
                <div class="main-activity">
                    <div class="main-mode-top">
                        <div class="top-left">
                            <img src="../../image/home/title-logo.png">
                            <h2>{{$t('home.activity')}}</h2>
                        </div>
                        <div class="top-right" @click="goToClick(1)">
                            <span>{{$t('home.more')}}</span>
                            <img src="../../image/home/right2.png">
                        </div>
                    </div>
                    <div class="main-activity-item" v-for="(item,index) in activityList" :key="index" @click="activityDetails(item.id)">
                        <img :src="item.src" :alt="item.name">
                        <div class="main-activity-item-detail">
                            <span>{{$t('home.more')}}</span>
                            <img src="../../image/home/right2.png">
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="news-bg">
                <div class="main-news">
                    <div class="main-mode-top">
                        <div class="top-left">
                            <img src="../../image/home/title-logo.png">
                            <h2>{{$t('home.news')}}</h2>
                        </div>
                        <div class="top-right" @click="goToClick(2)">
                            <span>{{$t('home.more')}}</span>
                            <img src="../../image/home/right2.png">
                        </div>
                    </div>
                    <div class="news-content">
                        <div @click="newsDetails(item.id)" v-for="(item,index) in newsList" :key="index" class="news-content-item">
                            <div class="item-img">
                                <img :src="item.src" :alt="item.title" :title="item.title">
                            </div>
                            <div class="item-info">
                                <h3>{{item.title}}</h3>
                                <div class="info-content">
                                    {{item.detail || '暂无介绍'}}
                                </div>
                                <div class="detail" @click.stop="newsDetails(item.id)">
                                    <span>{{$t('home.details')}}</span>
                                    <div class="detail-bg"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="project-bg">
                <div class="main-project">
                    <div class="main-mode-top">
                        <div class="top-left" style="color:#fff">
                            <img src="../../image/home/title-logo2.png">
                            <h2>{{$t('home.project')}}</h2>
                        </div>
                        <div class="top-right" style="color:#fff" @click="goToClick(3)">
                            <span>{{$t('home.more')}}</span>
                            <img src="../../image/home/right3.png">
                        </div>
                    </div>
                    <div class="project-content">
                        <div @click="projectClick(item.id)" v-for="(item,index) in projectList" :key="index" class="news-content-item">
                            <div class="item-img">
                                <img :src="item.imgSrc" :alt="item.name" :title="item.name">
                            </div>
                            <div class="item-info">
                                <h3>{{item.name}}</h3>
                                <div class="info-content">
                                    {{item.sketch || '暂无介绍'}}
                                </div>
                                <div class="detail" @click.stop="projectClick(item.id)">
                                    <span>{{$t('home.details')}}</span>
                                    <div class="detail-bg"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="business">
                <div class="main-business-box">
                    <div class="main-mode-top">
                        <div class="top-left" style="color:#fff">
                            <img src="../../image/home/title-logo2.png">
                            <h2>{{$t('home.business')}}</h2>
                        </div>
                        <div class="top-right" style="color:#fff" @click="goToClick(4)">
                            <span>{{$t('home.more')}}</span>
                            <img src="../../image/home/right3.png">
                        </div>
                    </div>
                    <div class="main-business">
                        <div v-for="(item,index) in businessList" :key="index" class="business-content" @click="businessClick(item.id)">
                            <img v-if="item.imgSrc" :src="item.imgSrc" :alt="item.name" class="business-img">
                            <img v-else src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/695c1c998419414586ac702b35400ee5_mergeImage.png" class="business-img">
                            <div class="business-content-title">
                                <div>{{item.name}}</div>
                                <div>
                                    <span>{{$t('home.more')}}</span>
                                    <img src="../../image/home/right3.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="business-shadow"></div>
                <div class="business-left" @click="businessLeftClick"></div>
                <div class="business-right" @click="businessRightClick"></div>
            </div>
            <div class="brand-bg">
                <div class="main-brand">
                    <div class="main-mode-top">
                            <div class="top-left">
                                <img src="../../image/home/title-logo.png">
                                <h2>{{$t('home.brand')}}</h2>
                            </div>
                        </div>
                    <div class="main-brand-content">
                        <div v-for="(item,index) in brandList" :key="index" class="brand-content-item" >
                            <div class="brand-content-item-bg">
                                <img :src="item.src" alt="" :title="item.name">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="our-bg">
                <div class="main-our">
                    <!-- <h2>{{$t('home.ours')}}</h2> -->
                    <div class="main-mode-top">
                        <div class="top-left" >
                            <img src="../../image/home/title-logo.png">
                            <h2>{{$t('home.ours')}}</h2>
                        </div>
                        <div class="top-right" @click="ourClick">
                            <span>{{$t('home.more')}}</span>
                            <img src="../../image/home/right2.png">
                        </div>
                    </div>
                    <div class="our-info">
                        <img class="our-image" src="../../image/home/our.png" alt="">
                        <div class="our-content">
                            <div v-html="ourInfo.content" class="our-content-info"></div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </main>
    </div>
</template>

<script>
    import {getBanner,getActivity,getBrand,getOur,getNew,getProject,getBusiness,getBusinessInfo} from '../../api/homeApi'
    import videoPlayer from '../component/video-player.vue'
    export default {
        components: {
            videoPlayer,
        },
        data() {
            return {
                searchValue: '',
                type: 0,
                menuList: ['关于我们','赛道探索','赛事活动','新闻资讯','娱乐体验','联系我们'],
                menuOption: 0,
                projectOption: 0,
                lunboIdx: 0,
                scrollTop: 0,
                bannerSrc: '',
                mobileBannerSrc: '',
                bannerType: 1, //1.图片 0.视频
                bannerTitle: '',
                player: null,
                activityList: [],
                newsList: [],
                projectList: [],
                brandList: [],
                ourInfo: {},
                businessList: [],
                windowWidth: 0,
                businessLen: 1,
                businessEndIdx: 2,
                carouselStatus: true, //true.先左 false.向右
                timer: null,
                bgTimer: null,
                brandIdx: 0,
            }
        },
        computed: {
            
        },
        created() {
            this.getBanner().then(() => {
                let myvideo = document.getElementById('startvideo');
                let bgBox = document.getElementsByClassName('banner-img')[0];
                if(myvideo) {
                    myvideo.addEventListener('timeupdate', () => {
                        var playTime
                        playTime = Math.floor(myvideo.currentTime)
                        if(playTime >= Math.floor(myvideo.duration) ) {
                            bgBox.style.opacity = 0.6
                            bgBox.style.transition = ''
                        }else {
                            if(playTime == 0) {
                                if(!bgBox.style.transition) {
                                    bgBox.style.opacity = 0
                                    bgBox.style.transition = '4s all'
                                }
                            }
                        }
                    });
                    
                }
            })
            this.getActivity()
            this.getBrand()
            this.getNew()
            this.getOur()
            this.getProject()
            this.getBusiness()
            // this.getBusinessInfo()
            setTimeout(() => {
                this.brandLunbo()
            },500)
            this.getWindowWidth()
            window.addEventListener("resize", this.getWindowWidth);
           
        },
        destroyed() {
            window.removeEventListener("resize", this.getWindowWidth)
            if(this.timer) {
                clearInterval(this.timer)
            }
        },
        methods: {
            // 轮播图播放
            cachesTimer() {
                this.timer = setInterval(() => {
                    // this.rightClick()
                    this.businessRightClick()
                    this.brandLunbo()
                },5000)
            },
            _isMobile() {
                let flag = navigator.userAgent.match(
                /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
                );
                return flag;
            },
            getWindowWidth() {
                this.windowWidth = document.documentElement.clientWidth
                if(this.timer) {
                    clearInterval(this.timer)
                }
                this.cachesTimer()
            },
            handleCommand(command) {
                this.type = command
            },
            menuClick(val) {
                this.menuOption = val
            },
            leftClick() {
                var lunbo = document.querySelector('.activity-luno');
                if(this.lunboIdx != 0) {
                    this.lunboIdx --
                    let num = `${this.lunboIdx}` * 100 
                    lunbo.style.left = `-${num}%`
                }else {
                    this.lunboIdx = this.activityList.length - 1
                    let num = `${this.lunboIdx}` * 100
                    lunbo.style.left = `-${num}%`
                }
                if(this.timer) {
                    clearInterval(this.timer)
                }
                this.cachesTimer()
            },
            rightClick() {
                var lunbo = document.querySelector('.activity-luno');
                if(this.lunboIdx < this.activityList.length - 1) {
                    this.lunboIdx ++
                    let num = `${this.lunboIdx}` * 100
                    lunbo.style.left = `-${num}%`
                }else {
                    this.lunboIdx = 0
                    lunbo.style.left = `0`
                    // lunbo.style.transition = `none`
                }
                if(this.timer) {
                    clearInterval(this.timer)
                }
                this.cachesTimer()
            },
            lunboClick(val) {
                var lunbo = document.querySelector('.activity-luno');
                this.lunboIdx = val
                let num = `${this.lunboIdx}` * 100 
                lunbo.style.left = `-${num}%`
                if(this.lunboIdx == 0) {
                    this.carouselStatus = true
                }else if(this.lunboIdx == this.activityList.length - 1) {
                    this.carouselStatus = false
                }
                if(this.timer) {
                    clearInterval(this.timer)
                }
                this.cachesTimer()
            },
            businessLeftClick() {
                var pic = document.querySelector('.main-business');
                if(this.businessEndIdx == 0) {
                    this.businessEndIdx = this.businessLen
                    var num = this.businessEndIdx * 25
                    pic.style.left = `-${num}%`
                }
                this.businessEndIdx --
                var index = this.businessEndIdx
                this.animation({
                    ele:pic,
					target : -pic.children[0].offsetWidth*index, 
					attr : 'left'
                })
                if(this.timer) {
                    clearInterval(this.timer)
                }
                this.cachesTimer()
            },
            businessRightClick() {
                var pic = document.querySelector('.main-business');
                if(this.businessEndIdx == this.businessLen) {
                    this.businessEndIdx = 0 
                    pic.style.left = 0
                }
                this.businessEndIdx ++
                var index = this.businessEndIdx
                this.animation({
                    ele:pic,
					target : -pic.children[0].offsetWidth*index, 
					attr : 'left'
                })
                if(this.timer) {
                    clearInterval(this.timer)
                }
                this.cachesTimer()
            },
            animation(options){
                // console.log(options);
                //先清除再开启
                let that = this
                clearInterval(options.ele.timer);
                //使用定时器
                options.ele.timer = setInterval(function(){
                    //获取到现在的位置
                    var begin = parseInt(that.getStyle(options.ele, options.attr));
                    //步长  step = (target - begin)/10
                    var step = (options.target - begin)/10;
                    step = step > 0 ? Math.ceil(step) : Math.floor(step); 
                    //位置+步长
                    var res = begin + step;
                    //赋值
                    options.ele.style[options.attr] = res + 'px';
                    
                    if(res == options.target){
                        clearInterval(options.ele.timer);
                        //当有这个函数名称的时候，再调用函数
                        //两个条件必须都满足，才能执行
                        options.callback && options.callback();
                    }
                }, 30)
            },
            getStyle(obj, attr){
                if(window.getComputedStyle){
                    return getComputedStyle(obj, null)[attr];
                }else{
                    return obj.currentStyle[attr];
                }
            },
            backClick() {
                const that = this
                let timer = setInterval(() => {
                    let ispeed = Math.floor(-that.scrollTop / 5)
                    document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
                    if (that.scrollTop === 0) {
                        clearInterval(timer)
                    }
                }, 16)
            },
            // 获取banner图
            async getBanner() {
                await getBanner({
                    plate: 0,
                }).then(res => {
                    if(res.code == 0) {
                        let arr = JSON.parse(res.infos[0].image)
                        if([
                            'image/png',    // .png
                            'image/jpeg',   // .jpg .jpeg
                            'image/x-ms-bmp', // .bmp
                            'image/gif',    // .gif
                            'image/tiff',   // .tif .tiff
                        ].includes(arr[0].type)) {
                            this.bannerType = 1
                        }else {
                            this.bannerType = 0
                        }
                        this.bannerSrc = JSON.parse(res.infos[0].image)[0].url
                        this.bannerTitle = res.infos[0].content
                        let mobileArr = JSON.parse(res.infos[0].mobileImage)
                        if(mobileArr.length > 0) {
                            this.mobileBannerSrc = mobileArr[0].url
                        }
                    }
                })
            },
            // 获取活动
            getActivity() {
                getActivity({
                    limit: 6,
                    page: 1,
                }).then(res => {
                    if(res.code == 0) {
                        this.activityList = res.page.list.map(item => {
                            let str = item.details.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "")
                            item.detail = str
                            let arr = item.endTime.split('-')
                            item.month = arr[1]
                            item.day = arr[2]
                            item.src = ''
                            let arr2 = JSON.parse(item.image)
                            if(arr2.length > 0) {
                                item.src = arr2[0].url
                            }
                            return item
                        })
                    }
                })
            },
            // 获取咨询
            getNew() {
                getNew({
                    limit: 3,
                    page: 1,
                }).then(res => {
                    if(res.code == 0) {
                        this.newsList = res.page.list.map(item => {
                            let str = item.content.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "")
                            item.detail = str
                            item.src = JSON.parse(item.image)[0].url
                            return item
                        })
                    }
                })
            },
            // 获取项目
            getProject() {
                getProject({
                    limit: 3,
                    page: 1,
                }).then(res => {
                    if(res.code == 0) {
                        this.projectList = res.page.list
                        res.page.list.forEach((item,idx) => {
                            item.imgSrc = JSON.parse(item.image)[0].url
                        })
                    }
                })
            },
            // 获取合作品牌
            getBrand() {
                getBrand({
                    limit: -1,
                    page: 1,
                }).then(res => {
                    if(res.code == 0) {
                        this.brandList = []
                        let arr = res.page.list.map(item => {
                            item.src = JSON.parse(item.image)[0].url
                            return item
                        })
                        if(arr.length > 5) {
                            let start = JSON.parse(JSON.stringify(arr)).splice(0,4)
                            let end = arr[arr.length-1]
                            let arr2 = arr
                            arr2.unshift(end)
                            start.forEach(o => {
                                arr2.push(o)
                            })
                            this.brandList = arr2
                        }else {
                            this.brandList = arr
                        }
                    }else {
                        this.brandList = []
                    }
                })
            },
            // 合作品牌轮播
            brandLunbo() {
                var pic = document.querySelector('.main-brand-content');
                if(this.brandIdx == pic.children.length - 5) {
                    this.brandIdx = 0
                    pic.style.left = 0
                }
                this.brandIdx ++
                var index = this.brandIdx
                this.animation({
                    ele:pic,
                    target : -pic.children[0].offsetWidth*index, 
                    attr : 'left'
                })
            },
            // 关于我们
            getOur() {
                getOur().then(res => {
                    if(res.code == 0) {
                        this.ourInfo = res.info
                    }
                })
            },
            // 获取业务
            getBusiness() {
                getBusiness().then(res => {
                    if(res.code == 0) {
                        this.businessList = res.infos
                        res.infos.forEach((item,idx) => {
                            item.imgSrc = JSON.parse(item.image)[0].url
                        })
                        this.businessLen = res.infos.length
                        if(res.infos.length >= 4) {
                            let num = this.businessLen -1
                            let startArr = []
                            let endArr = []
                            res.infos.forEach((item,idx) => {
                                if(idx == 1 || idx ==0 ) {
                                    endArr.push(item)
                                }else if(idx == num || idx == num - 1) {
                                    startArr.push(item)
                                }
                            })
                            this.businessList = startArr.concat(this.businessList).concat(endArr)
                        }
                    }
                })
            },
            
            goToClick(val) {
                if(val == 1) {
                    this.$router.push('/activity')
                }else if(val == 2) {
                     this.$router.push('/news')
                }else if(val == 3) {
                    this.$router.push('/home/projectList')
                }else if(val == 4) {
                    this.$router.push('/home/businessList')
                }
            },
            // 新闻详情
            newsDetails(id) {
                this.$router.push(`/news-details/${id}`)
            },
            // 活动详情
            activityDetails(id) {
                this.$router.push(`/activity-details/${id}`)
            },
            // 业务详情
            businessClick(id) {
                this.$router.push(`/home/business/${id}`)
            },
            projectClick(id) {
                this.$router.push(`/home/project/${id}`)
            },
            ourClick() {
                this.$router.push(`/home/our`)
            },
        }
    }
</script>

<style lang="less" scoped>

    .home-page {
        width: 100%;
        .bannar {
            width: 100%;
            height: 100%;
            // height: 5.5rem;
            background: #666;
            position: relative;
            .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                background: rgba(0,0,0,0);
                z-index: 9;
            }
            img {
                // height: 100%;
                width: 100%;
                vertical-align: middle;
                height: calc((725/1920) * 100vw);
                min-height: 500px;
                max-height: 725px;
            }
            .bannar-info {
                position: absolute;
                top: 0%;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                z-index: 9;
                .banner-img {
                    height: 100%;
                    // width: 1820px;
                    position: absolute;
                    // padding-right: 720px;
                    top: 0;
                    left: -720px;
                    opacity: .63;
                }
                .banner-content {
                    position: absolute;
                    z-index: 9;
                    padding-left: 90px;
                    top: 55%;
                    text-align: left;
                }
                .bannar-title {
                    font-size: 42px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .bannar-detail {
                    font-size: 24px;
                    color: #fff;
                    // cursor: pointer;
                }
            }
            .video-player {
                width: 100%;
                height: 100%;
            }
            .video {
                object-fit: fill;
                width: 100%;
                height: calc((725/1920) * 100vw);
                min-height: 500px;
            }
        }
        main {
            width: 100%;
            background: url('../../image/home/bg.jpg') center center no-repeat;
            .main-mode-top {
                display: flex;
                justify-content: space-between;
                height: 50px;
                width: 100%;
                margin-bottom: 50px;
                .top-left {
                    display: flex;
                    align-items: center;
                    img {
                        width: 55px;
                        height: 15px;
                        margin-right: 10px;
                    }
                    h2 {
                        margin: 0;
                        font-size: 28px;
                    }
                }
                .top-right {
                    display: flex;
                    align-items: center;
                    font-size: 22px;
                    cursor: pointer;
                    img {
                        width: 8px;
                        height: 14px;
                        margin-left: 10px;
                    }
                }
            }
            .our-bg {
                width: 100%;
                background: url('../../image/home/our.jpg') center center no-repeat;
                background-size: 100% 100%;
                // background-color: #F1F1F1;
            }
            .main-our {
                box-sizing: border-box;
                padding: .8rem 0rem 1rem;
                width: 1380px;
                margin: 0 auto;
                // background: #F1F1F1;
                h2 {
                    text-align: center;
                    margin: 0;
                    margin-bottom: .3rem;
                }
                .our-info {
                    display: flex;
                    .our-image {
                        width: 525px;
                        height: 220px;
                        margin-right: 50px;
                    }
                }
                /deep/.our-content {
                    // margin-bottom: .3rem;
                    font-size: .16rem;
                    color: #666;
                    line-height: 2;
                    display: -webkit-box;
                    -webkit-line-clamp:7;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    p {
                        margin: 0;
                        span {
                            color: #666 !important;
                            display: inline-block;
                            text-indent: 2em;
                        }
                    }
                }
                .our-detail {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .detial {
                        border: 1px solid #4C4C4C;
                        padding: .05rem .2rem;
                        // transition: .4s all;
                        cursor: pointer;
                        transition: border-color .3s ease,color .2s ease,background-color .3s ease;
                    }
                    .detial:hover {
                        background: #4C4C4C;
                        color: #fff;
                        transition: .4s all;
                    }
                }
            }
            .project-bg {
                background: url('../../image/home/project.jpg') center center no-repeat;
                background-size: 100% 100%;
            }
            .main-project {
                box-sizing: border-box;
                padding: 1.2rem 0rem 1.2rem 0rem;
                // width: 1200px;
                width: 1380px;
                margin: 0 auto;
                h2 {
                    text-align: center;
                    margin: 0;
                    margin-bottom: .8rem;
                }
                .project-content {
                    display: flex;
                    flex-wrap: wrap;
                    .news-content-item:nth-child(3n) {
                        margin-right: 0;
                    }
                    .news-content-item:hover {
                        .item-info {
                            background-color: #ff6720;
                            color: #fff;
                            transition: .5s all;
                            .info-content {
                                color: #fff !important;
                                transition: .5s all;
                            }
                            .detail {
                                color: #fff;
                                transition: .5s all;
                                .detail-bg {
                                    background: url('../../image/home/right3.png') center center no-repeat;
                                    background-size: 100% 100%;
                                    height: 14px;
                                    width: 8px;
                                }
                            }
                        }
                    }
                    .news-content-item {
                        width: calc(100%/3 - .8rem/3);
                        margin-right: .4rem;
                        cursor: pointer;
                        box-shadow: 1px 1px 10px #333;
                        .item-img {
                            width: 100%;
                            height: 2.4rem;
                            overflow: hidden;
                            background: #ccc;
                            img {
                                width: 100%;
                                height: 100%;
                                transition: .5s all;
                            }
                            img:hover {
                                transform: scale(1.1);
                                transition: .5s all;
                            }
                        }
                        .item-info {
                            background-color: #fff;
                            box-sizing: border-box;
                            padding: .2rem;
                            transition: .5s all;
                            h3 {
                                margin: 0;
                                padding-bottom: .1rem;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 22px;
                            }
                            .info-content {
                                font-size: .14rem;
                                line-height: 2;
                                margin-bottom: .2rem;
                                display: -webkit-box;
                                -webkit-line-clamp:2;
                                -webkit-box-orient: vertical;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                color: #666;
                                transition: .5s all;
                                min-height: 60px;
                            }
                            .detail {
                                padding: .05rem .2rem;
                                // border: 1px solid #4C4C4C;
                                width: 100%;
                                font-size: .16rem;
                                color: #ff6720;
                                transition: .5s all;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                .detail-bg {
                                    background: url('../../image/home/right2.png') center center no-repeat;
                                    background-size: 100% 100%;
                                    height: 14px;
                                    width: 8px;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }
                }
            }
            .business {
                // background-color: rgba(255,103,32,.8);
                background: url('../../image/home/project.jpg') center center no-repeat;
                background-size: 100% 100%;
                // padding: 0 270px;
                position: relative;
                padding-bottom: 200px;
                padding-top: 119px;
                .business-shadow {
                    background: url('../../image/home/project-shadow.png') center center no-repeat;
                    background-size: 100%;
                    height: 60px;
                    margin: 0 270px;
                    margin-top: 30px;
                }
                .business-left {
                    background: url('../../image/home/left.png') center center no-repeat;
                    height: 40px;
                    width: 40px;
                    background-size: 100%;
                    position: absolute;
                    left: 150px;
                    top: 310px;
                    cursor: pointer;
                }
                .business-right {
                    background: url('../../image/home/right.png') center center no-repeat;
                    height: 40px;
                    width: 40px;
                    background-size: 100%;
                    position: absolute;
                    right: 160px;
                    top: 310px;
                    cursor: pointer;
                }
            }
            .main-business-box {
                position: relative;
                overflow: hidden;
                width: 1380px;
                margin: 0 auto;
                // transition: 1s all;
            }
            .main-business {
                width: 100%;
                display: flex;
                position: relative;
                left: -50%;
                .business-word {
                    padding: 40px 80px;
                }
                .business-bg {
                    background-color: #ee6a22 !important;
                }
                .business-content:hover {
                    .business-img {
                        transform: scale(1.1);
                        transition: .5s all;
                    }
                }
                .business-content {
                    display:flex;
                    width:25%;
                    flex-shrink: 0;
                    position: relative;
                    padding-right: 10px;
                    height: 220px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        transition: .5s all;
                    }
                    .business-content-title {
                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        width: 100%;
                        font-size: 16px;
                        color: #fff;
                        text-align: right;
                        padding: 5px 10px;
                        box-sizing: border-box;
                        background-color: rgba(255,103,32,.5);
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;
                        div:first-child {
                            flex: 1;
                            margin-right: 20px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: left;
                        }
                        img {
                            height: 14px;
                            width: 8px;
                            margin: 0 10px;
                        }
                    }
                }
                
            }
            .activity-bg {
                background: url('../../image/home/activity.jpg') center center no-repeat;
                background-size: 100% 100%;
            }
            .main-activity {
                box-sizing: border-box;
                padding: 1.2rem 0rem;
                // width: 1200px;
                width: 1380px;
                display: flex;
                margin: 0 auto;
                // min-height: 6.9rem;
                flex-wrap: wrap;
                .main-activity-item:nth-child(3n + 1) {
                    margin-right: 0;
                }
                .main-activity-item:hover {
                    img {
                        transform: scale(1.1);
                        transition: .5s all;
                    }
                }
                .main-activity-item {
                    width: calc((100% - 80px)/3);
                    height: 242px;
                    background-color: #fff;
                    margin-bottom: 40px;
                    margin-right: 40px;
                    position: relative;
                    overflow: hidden;
                    box-shadow: 1px 1px 10px #ccc;
                    img {
                        height: 100%;
                        width: 100%;
                        vertical-align: middle;
                        transition: .5s all;
                    }
                    .main-activity-item-detail {
                        font-size: 16px;
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        cursor: pointer;
                        img {
                            width: 8px;
                            height: 14px;
                            margin-left: 10px;
                        }
                    }
                }
            }
            .news-bg {
                width: 100%;
                background: url('../../image/home/news.jpg') center center no-repeat;
                background-size: 100% 100%;
                // background-color: #EBEBEB;
            }
            .main-news {
                box-sizing: border-box;
                padding: 1.2rem 0rem 1.7rem 0rem;
                // height: 7.05rem;
                margin: 0 auto;
                width: 1380px;
                .news-top {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: .6rem;
                    h2 {
                        margin: 0;
                    }
                    .detail {
                        // padding: .05rem .2rem;
                        border: 1px solid #333;
                        width: 1.4rem;
                        height: .4rem;
                        // width: max-content;
                        font-size: .16rem;
                        color: #4C4C4C;
                        cursor: pointer;
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                        transition: .5s all;
                        .details-bg {
                            background: url('../../image/right.png') center center no-repeat;
                            background-size: 100%;
                            height: 25px;
                            width: 30px;
                        }
                        img {
                            height: 25px;
                            margin-left: 20px;
                        }
                    }
                    .detail:hover {
                        background-color: #333;
                        color: #fff;
                        .details-bg {
                            background: url('../../image/right3.png') center center no-repeat;
                            background-size: 100%;
                        }
                    }
                }
                .news-content {
                    display: flex;
                    flex-wrap: wrap;
                    .news-content-item:nth-child(3n) {
                        margin-right: 0;
                    }
                    .news-content-item:hover {
                        .item-info {
                            background-color: #ff6720;
                            color: #fff;
                            transition: .5s all;
                            .info-content {
                                color: #fff !important;
                                transition: .5s all;
                            }
                            .detail {
                                color: #fff;
                                transition: .5s all;
                                .detail-bg {
                                    background: url('../../image/home/right3.png') center center no-repeat;
                                    background-size: 100% 100%;
                                    height: 14px;
                                    width: 8px;
                                }
                            }
                        }
                    }
                    .news-content-item {
                        width: calc(100%/3 - .8rem/3);
                        margin-right: .4rem;
                        cursor: pointer;
                        box-shadow: 1px 1px 10px #ccc;
                        .item-img {
                            width: 100%;
                            height: 2.4rem;
                            overflow: hidden;
                            img {
                                width: 100%;
                                height: 100%;
                                transition: .5s all;
                            }
                            img:hover {
                                transform: scale(1.1);
                                transition: .5s all;
                            }
                        }
                        .item-info {
                            background-color: #fff;
                            box-sizing: border-box;
                            padding: .2rem;
                            transition: .5s all;
                            h3 {
                                margin: 0;
                                padding-bottom: .1rem;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 22px;
                            }
                            .info-content {
                                font-size: .14rem;
                                line-height: 2;
                                margin-bottom: .2rem;
                                display: -webkit-box;
                                -webkit-line-clamp:2;
                                -webkit-box-orient: vertical;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                color: #666;
                                transition: .5s all;
                                min-height: 60px;
                            }
                            .detail {
                                padding: .05rem .2rem;
                                // border: 1px solid #4C4C4C;
                                width: 100%;
                                font-size: .16rem;
                                color: #ff6720;
                                transition: .5s all;
                                cursor: pointer;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                .detail-bg {
                                    background: url('../../image/home/right2.png') center center no-repeat;
                                    background-size: 100% 100%;
                                    height: 14px;
                                    width: 8px;
                                    margin-left: 10px;
                                }
                            }
                            // .detail:hover {
                            //     transition: .5s all;
                            //     color: #ff6720;
                            // }
                        }
                    }
                }
            }
            .brand-bg {
                width: 100%;
                background: url('../../image/home/bus.jpg') center center no-repeat;
                background-size: 100% 100%;
            }
            .main-brand {
                box-sizing: border-box;
                padding: .8rem 0rem 1.1rem 0rem;
                width: 1380px;
                margin: 0 auto;
                overflow: hidden;
                position: relative;
                padding-bottom: .8rem;
                .main-brand-content {
                    display: flex;
                    position: relative;
                    margin-top: 100px;
                    .brand-content-item {
                        width: calc((80% + 145px)/4);
                        flex-shrink: 0;
                        .brand-content-item-bg {
                            overflow: hidden;
                            height: 125px;
                            width: 125px;
                            // border: 1px solid #ccc;
                            background-color: #fff;
                            img {
                                height: 100%;
                                width: 100%;
                                vertical-align: middle;
                                transition: .5s all;
                            }
                            img:hover {
                                transform: scale(1.2);
                                transition: .5s all;
                            }
                        }
                        .brand-content-item-title {
                            // height: 25%;
                            margin: 20px 0;
                            width: 100%;
                            font-size: 16px;
                            color: #333;
                            text-align: center;
                            font-weight: bold;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
        .back-top {
            position: fixed;
            right: 5%;
            bottom: 10%;
            cursor: pointer;
        }
    }
/deep/.our-content-info {
    p {
        span {
            background-color: transparent !important;
        }
    }
}
video::-webkit-media-controls{ 
    display:none !important;
} 
</style>