import Axios from 'axios'
import router from '@/router'
import '../../public/assets/global'

const Ajax = Axios.create({
    baseURL: window.BASE_URL
})

Ajax.interceptors.request.use(config => {
    // TODO something here
    return config
})
// 响应拦截器
Ajax.interceptors.response.use(response => {
    if (response.data && response.data.code === 502) { // 401, token失效
       router.push('/404')
      }
      return response
}, error => {
    router.push('/404')
    return Promise.reject(error)
})

export default req => {
    if (!req.url) throw new Error('请求地址必传')
    return new Promise(resolve => {
        Ajax.request({
            url: req.url,
            method: req.method || 'GET',
            data: req.data || {},
            params: req.params || {}
        }).then(({data}) => {
            resolve(data)
        }).catch(e => {
            resolve(e)
        })
    })
}