module.exports = {
    language: {
      name: '英文',
    },
    menu: {
      menu0: 'Home',
      menu1: 'Track',
      menu2: 'Activity',
      menu3: 'Cooperation',
      menu4: 'News',
      menu5: 'Recreation',
      menu6: 'Contact',
    },
    home: {
      activity: 'Event activity',
      news: 'News consultation',
      project: 'Project introduction',
      business: 'Business introduction',
      brand: 'Brand cooperation',
      ours: 'About us',
      details: 'Learn more',
      more: 'Learn more'
    }
};