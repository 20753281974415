import Vue from 'vue'
import Vuex from 'vuex'
import storeState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [
        storeState({
            storage: window.sessionStorage
        })
    ],
    modules: {
        common: {
            namespaced: true,
            state: {
                token: '',
                windowWidth: 0,
                // 遮罩控制
                overlayStatus: false,
            },
            getters: {
                getToken: state => state.token,
                getWindowWidth: state => state.windowWidth,
                getOverlayStatus: state => state.overlayStatus
            },
            actions: {
                setToken({commit}, token) {
                    commit('mutationToken', token)
                },
                setWindowWidth({commit}, windowWidth) {
                    commit('mutationWindowWidth', windowWidth)
                },
                setOverlayStatus({commit}, status) {
                    commit('mutationOverlayStatus', status)
                }
            },
            mutations: {
                mutationToken(state, token) {
                    state.token = token
                },
                mutationWindowWidth(state, windowWidth) {
                    state.windowWidth = windowWidth
                },
                mutationOverlayStatus(state, status) {
                    state.overlayStatus = status
                },
            }
        }
    }
})

export default store