import Vue from 'vue'
import App from './pages'

import ElementUI from 'element-ui'
Vue.use(ElementUI)
import 'element-ui/lib/theme-chalk/index.css'

import {formatUploadURL} from './util/index.js'
Vue.prototype.formatImageUrl = formatUploadURL;

// import '../public/assets/global'

// import './util/rem'

// import VideoPlayer from 'vue-video-player'
// import 'vue-video-player/src/custom-theme.css'
// import 'video.js/dist/video-js.css'
// Vue.use(VideoPlayer)

import GlobalComponent from './component'
Vue.use(GlobalComponent)
import "./style/index.less"

import router from './router'
import store from './store'

import i18n from './component/language/index';

new Vue({
  el: '#root',
  router,
  store,
  i18n,
  render(ce) {
    return ce(App)
  }
})

Vue.config.productionTip = false