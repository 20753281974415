<template>
    <div class="news-page">
        <!-- <top-box :option="3"></top-box> -->
        <div class="bannar">
           <!-- <img src="../../image/bg.png"> -->
            <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
            <img :src="bannerSrc" v-if="bannerType">
            <video v-else muted="muted" id="startvideo" class="video" :src="bannerSrc" type="video/mp4"  autoplay="autoplay" controls="controls" loop="-1">
                <p>你的浏览器不支持video标签.</p>
            </video>
            <div class="overlay"></div>
            <div class="bannar-info">
                <!-- <div class="bannar-title">新闻资讯</div>
                <div class="bannar-detail">{{bannerTitle}}</div> -->
                <div class="banner-content">
                    <div class="bannar-title">天府国际赛道</div>
                    <div class="bannar-detail">{{bannerTitle}}</div>
                </div>
                <img class="banner-img" src="../../image/top/banner4.png">
            </div>
        </div>
        <main>
            <div class="main-content">
                <div v-for="(item,index) in businessList" :key="index" class="main-content-item" @click="detailsClick(item.id)">
                    <div class="main-content-item-img">
                        <img :src="item.imgSrc" :title="item.name">
                    </div>
                    <div class="info">
                        <div class="title">{{item.name}}</div>
                        <div class="content">
                            {{item.detail || '暂无'}}
                        </div>
                        <div class="time">{{item.releaseTime}}</div>
                        <div class="detail">
                            <span>{{$t('home.more')}}</span>
                            <img src="../../image/home/right2.png">
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
<script>
import {getBanner,getBusiness} from '../../api/homeApi'
export default {
    data() {
        return {
            optionIdx: 0,
            optionList: ['最新发布','公司新闻','行业动态'],
            bannerSrc: '',
            bannerType: 1, //1.图片 0.视频
            bannerTitle: '',
            businessList: [],
            total: 0,
            pageNum: 1,
            type: 0,
        }
    },
    created() {
        this.getBanner().then(() => {
            let myvideo = document.getElementById('startvideo');
            let bgBox = document.getElementsByClassName('banner-img')[0];
            if(myvideo) {
                myvideo.addEventListener('timeupdate', () => {
                    var playTime
                    playTime = Math.floor(myvideo.currentTime)
                    if(playTime >= Math.floor(myvideo.duration) ) {
                        bgBox.style.opacity = 0.6
                        bgBox.style.transition = ''
                    }else {
                        if(playTime == 0) {
                            if(!bgBox.style.transition) {
                                bgBox.style.opacity = 0
                                bgBox.style.transition = '4s all'
                            }
                        }
                    }
                });
                
            }
        })
        this.getBusiness()
    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(
            /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
            );
            return flag;
        },
        async getBanner() {
            await getBanner({
                plate: 0,
            }).then(res => {
                if(res.code == 0) {
                    if(res.infos.length > 0) {
                        let arr = JSON.parse(res.infos[0].image)
                        if([
                            'image/png',    // .png
                            'image/jpeg',   // .jpg .jpeg
                            'image/x-ms-bmp', // .bmp
                            'image/gif',    // .gif
                            'image/tiff',   // .tif .tiff
                        ].includes(arr[0].type)) {
                            this.bannerType = 1
                        }else {
                            this.bannerType = 0
                        }
                        this.bannerSrc = JSON.parse(res.infos[0].image)[0].url
                        this.bannerTitle = res.infos[0].content
                        let mobileArr = JSON.parse(res.infos[0].mobileImage)
                        if(mobileArr.length > 0) {
                            this.mobileBannerSrc = mobileArr[0].url
                        }
                    }
                }
            })
        },
        
        // 获取业务
        getBusiness() {
            getBusiness().then(res => {
                if(res.code == 0) {
                    this.businessList = res.infos
                    res.infos.forEach((item,idx) => {
                        item.imgSrc = JSON.parse(item.image)[0].url
                        let str = item.content.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "")
                        item.detail = str
                    })
                }
            })
        },
        detailsClick(id) {
            this.$router.push(`/home/business/${id}`)
        },
    }
}
</script>
<style lang="less" scoped>

    .news-page {
        background: url('../../image/home/bg.jpg') center center no-repeat;
        .bannar {
            width: 100%;
            height: 100%;
            // height: 5.5rem;
            background: #666;
            position: relative;
            img {
                vertical-align: middle;
                height: calc((725/1920) * 100vw);
                min-height: 500px;
                max-height: 725px;
                width: 100%;
            }
            .bannar-info {
                position: absolute;
                top: 0%;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                z-index: 9;
                .banner-img {
                    height: 100%;
                    // width: 1820px;
                    position: absolute;
                    // padding-right: 100px;
                    top: 0;
                    left: -720px;
                    opacity: .63;
                }
                .banner-content {
                    position: absolute;
                    z-index: 9;
                    padding-left: 90px;
                    top: 55%;
                    text-align: left;
                }
                .bannar-title {
                    font-size: 42px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .bannar-detail {
                    font-size: 28px;
                    color: #fff;
                    // cursor: pointer;
                }
            }
        }
        .main-option {
            display: flex;
            justify-content: center;
            margin-bottom: .6rem;
            background: url('../../image/top/top-bg.jpg') center center no-repeat;
            background-size: 100% 100%;
            height: 100px;
            .main-option-item:nth-last-child(1) {
                margin-right: 0;
            }
            .main-option-item {
                position: relative;
                // width: 1.8rem;
                height: .8rem;
                padding: 0 20px;
                // background-color: #F1F1F1;
                font-size: .18rem;
                color: #fff;
                text-align: center;
                line-height: .6rem;
                margin-right: .6rem;
                cursor: pointer;
                transform: skew(45deg);
                margin-top: 20px;
                p {
                    margin: 0;
                    transform: skew(-45deg);
                }
            }
            .main-option-item:hover {
                color: #ff6720;
                background-color: #fff;
            }
            .active {
                color: #ff6720;
                background-color: #fff;
            }
        }
        main {
            box-sizing: border-box;
            width: 1600px;
            padding: .8rem 0rem;
            // background-color: #fff;
            margin: 0 auto;
            
            .main-content {
                .main-content-item:nth-last-child(1) {
                    margin-bottom: 0;
                }
                .main-content-item:hover {
                    .info {
                        background-color: #f1f1f1;
                    }
                }
                .main-content-item {
                    width: 100%;
                    display: flex;
                    margin-bottom: .4rem;
                    cursor: pointer;
                    .main-content-item-img {
                        width: 4.3rem;
                        height: 2.6rem;
                        display: block;
                        flex-shrink: 0;
                        overflow: hidden;
                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            transition: .5s all;
                        }
                        img:hover {
                            transition: .5s all;
                            transform: scale(1.1);
                        }
                    }
                    
                    .info {
                        box-sizing: border-box;
                        padding: .3rem .4rem;
                        // border: 1px solid #E6E6E6;
                        background-color: #fff;
                        box-shadow: 1px 1px 10px #ccc;
                        flex: 1;
                        .title {
                            font-size: .24rem;
                            color: #333;
                            margin-bottom: .1rem;
                            font-weight: bold;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .content {
                            font-size: .18rem;
                            line-height: 2;
                            display: -webkit-box;
                            -webkit-line-clamp:3;
                            -webkit-box-orient: vertical;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            color: #666;
                            margin-bottom: .1rem;
                            height: 110px;
                        }
                        .time {
                            font-size: .16rem;
                            color: #666;
                        }
                        .detail {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            justify-content: flex-end;
                            img {
                                width: 8px;
                                height: 14px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
            .main-vedios {
                display: flex;
                flex-wrap: wrap;
                .main-vedios-item:nth-child(2n) {
                    margin-right: 0;
                }
                .main-vedios-item {
                    width: calc(50% - 20px);
                    margin-right: 40px;
                    margin-bottom: 40px;
                    position: relative;
                    cursor: pointer;
                    .vedios-img {
                        img {
                            height: 3.2rem;
                            width: 100%;
                            vertical-align: middle;
                        }
                    }
                    .vedios-info {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        background-color: rgba(0,0,0,.5);
                        color: #fff;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        box-sizing: border-box;
                        padding: 10px 20px;
                        .title {
                            font-size: 18px;
                            flex: 1;
                            margin-right: 20px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .time {
                            font-size: 12px;
                        }
                    }
                }
            }
            .pagination {
                margin-top: 40px;
                text-align: center;
            }
        }
    }
.news-page {
    /deep/.pagination {
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #EE6A23;
        }
        .el-pagination.is-background .el-pager li:not(.disabled):hover {
            color: #EE6A23;
        }
    }
}  
</style>