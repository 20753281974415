import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/pages/home'
import Explore from '@/pages/explore'
import Recreation from '@/pages/recreation'
import Contact from '@/pages/contact'
import News from '@/pages/news'
import NewsSearch from '@/pages/news/news-search'
import NewsDetails from '@/pages/newsdetails'
import Activity from '@/pages/activity'
import ActivityDetails from '@/pages/activityDetails'
import ExploreDetails from '@/pages/explore/details'
import RecreationDetails from '@/pages/recreation/details'
import Business from '@/pages/home/business'
import OurPage from '@/pages/our'
import Project from '@/pages/home/project'
import ProjectList from '@/pages/home/projectList'
import BusinessList from '@/pages/home/businessList'
import Cooperate from '@/pages/cooperate'
import CooperateDetail from '@/pages/cooperate/details'
 
Vue.use(Router)

const plugin = Router.prototype.push
Router.prototype.push = function(config) {
    return plugin.call(this, config).catch(() =>{
        console.log('点击了同一个路由')
    })
}

const ISMOBILE = function () {
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
};
const routersP = [
    {path: '/', redirect: '/home'},
    {path: '/home', component: Home, name: 'home'},
    {path: '/home/business/:id', component: Business, name: 'home-business'},
    {path: '/home/project/:id', component: Project, name: 'home-project'},
    {path: '/home/projectList', component: ProjectList, name: 'home-projectList'},
    {path: '/home/businessList', component: BusinessList , name: 'home-businessList'},
    {path: '/home/our', component: OurPage, name: 'home-our'},
    {path: '/explore', component: Explore, name: 'explore'},
    {path: '/explore-details/:id', component: ExploreDetails, name: 'explore-details'},
    {path: '/recreation', component: Recreation, name: 'recreation'},
    {path: '/recreation-details/:id', component: RecreationDetails, name: 'recreation-details'},
    {path: '/contact', component: Contact, name: 'contact'},
    {path: '/news', component: News, name: 'news'},
    {path: '/news-search', component: NewsSearch, name: 'news-search'},
    {path: '/news-details/:id', component: NewsDetails, name: 'news-details'},
    {path: '/activity', component: Activity, name: 'activity'},
    {path: '/cooperate', component: Cooperate, name: 'cooperate'},
    {path: '/cooperate-details/:id', component: CooperateDetail, name: 'cooperate-details'},
    {path: '/activity-details/:id', component: ActivityDetails, name: 'activity-details'},
    {path: '/404',component: () => import('@/pages/component/404'), name: '404'},
]

const routersM = [
    {path: '/', redirect: '/home'},
    {path: '/home', component: () => import('@/pages/mobile/home'), name: 'mobile-home'},
    {path: '/home/business/:id', component: () => import('@/pages/mobile/home/business'), name: 'mobile-home-business'},
    {path: '/home/businessList', component: () => import('@/pages/mobile/home/businessList'), name: 'mobile-home-businessList'},
    {path: '/home/project/:id', component: () => import('@/pages/mobile/home/project'), name: 'mobile-home-project'},
    {path: '/home/projectList', component: () => import('@/pages/mobile/home/projectList'), name: 'mobile-home-projectList'},
    {path: '/home/our', component: () => import('@/pages/mobile/our'), name: 'mobile-home-our'},
    {path: '/explore', component: () => import('@/pages/mobile/explore'), name: 'mobile-explore'},
    {path: '/explore-details/:id', component: () => import('@/pages/mobile/explore/details'), name: 'mobile-explore-details'},
    {path: '/recreation', component: () => import('@/pages/mobile/recreation'), name: 'mobile-recreation'},
    {path: '/recreation-details/:id', component: () => import('@/pages/mobile/recreation/details'), name: 'mobile-recreation-details'},
    {path: '/contact', component: () => import('@/pages/mobile/contact'), name: 'mobile-contact'},
    {path: '/news', component: () => import('@/pages/mobile/news'), name: 'mobile-news'},
    {path: '/news-search', component: () => import('@/pages/mobile/news/news-search'), name: 'mobile-news-search'},
    {path: '/news-details/:id', component: () => import('@/pages/mobile/newsdetails'), name: 'mobile-news-details'},
    {path: '/activity', component: () => import('@/pages/mobile/activity'), name: 'mobile-activity'},
    {path: '/activity-details/:id', component: () => import('@/pages/mobile/activityDetails'), name: 'mobile-activity-details'},
    {path: '/cooperate', component: () => import('@/pages/mobile/cooperate'), name: 'mobile-cooperate'},
    {path: '/cooperate-details/:id', component: () => import('@/pages/mobile/cooperate/details'), name: 'mobile-cooperate-details'},
    {path: '/404',component: () => import('@/pages/component/404'), name: '404'},
]

const router = new Router({
    mode: 'history',
    routes: ISMOBILE() ? routersM : routersP
    // routes: [
    //     {path: '/', redirect: '/home'},
    //     {path: '/home', component: Home, name: 'home'},
    //     {path: '/home/business/:id', component: Business, name: 'home-business'},
    //     {path: '/home/our', component: OurPage, name: 'home-our'},
    //     {path: '/explore', component: Explore, name: 'explore'},
    //     {path: '/explore-details/:id', component: ExploreDetails, name: 'explore-details'},
    //     {path: '/recreation', component: Recreation, name: 'recreation'},
    //     {path: '/recreation-details/:id', component: RecreationDetails, name: 'recreation-details'},
    //     {path: '/contact', component: Contact, name: 'contact'},
    //     {path: '/news', component: News, name: 'news'},
    //     {path: '/news-search', component: NewsSearch, name: 'news-search'},
    //     {path: '/news-details/:id', component: NewsDetails, name: 'news-details'},
    //     {path: '/activity', component: Activity, name: 'activity'},
    //     {path: '/activity-details/:id', component: ActivityDetails, name: 'activity-details'},
    //     {path: '/404',component: () => import('@/pages/component/404'), name: '404'},
    // ]
})



router.beforeEach((to, from, next) => {
    // TODO something here
    if(to.matched.length === 0) {
        next('/404')
    }else {
        next()
    }
    // next()

})

export default router