<template>
    <div class="application-container">
        <top-box v-if="show && !_isMobile()"></top-box>
        <mobile-top-box v-if="_isMobile()"></mobile-top-box>
        <div class="main" ref="main">
            <div v-if="_isMobile()" style="height:60px;width:100%"></div>
            <div v-else style="height:120px;width:100%"></div>
            <router-view />
            <bottom-box  v-if="show && !_isMobile()"></bottom-box>
            <mobile-bottom-box v-if="_isMobile()"></mobile-bottom-box>
        </div>
        <div v-show="backShow">
            <div class="back-top" ref="back" @click="backClick">
                <img src="../image/top.png" alt="">
                返回顶部
            </div>
        </div>
    </div>
</template>

<script>
    import topBox from './component/top.vue'
    import bottomBox from './component/bottom.vue'
    import mobileTopBox from './mobile/component/top.vue'
    import mobileBottomBox from './mobile/component/bottom.vue'
    export default {
        components: {
            topBox,
            bottomBox,
            mobileTopBox,
            mobileBottomBox
        },
        data() {
            return {
                backShow: false,
                tid: null,
                scrollTop: 0,
                show: true,
            }
        },
        watch:{
            '$route':function(to,from){
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                if(to.path == '/404') {
                    this.show = false
                }else {
                    this.show = true
                }
            }
        },
        created() {
            window.addEventListener("scroll", this.handleScroll);
            this.$nextTick(() => {
                if(this._isMobile()) {
                    document.getElementsByTagName('body')[0].style.zoom = 1
                }else {
                    this.setScale()
                }
            })
            window.addEventListener("resize", () => {
                if(this._isMobile()) {
                    document.getElementsByTagName('body')[0].style.zoom = 1
                }else {
                    clearTimeout(this.tid)
                    this.tid = setTimeout(this.setScale,300)
                }
            },false);
        },
        destroyed() {
            window.removeEventListener("scroll", this.handleScroll)
        },
        methods: {
            _isMobile() {
                let flag = navigator.userAgent.match(
                /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
                );
                return flag;
            },
            handleScroll() {
                var width = document.documentElement.clientWidth;
                let scale = 1
                this.scrollTop = document.documentElement.scrollTop
                var scrollTop = document.documentElement.scrollTop
                if(width < 1800) {
                    scale = width/1800
                    scrollTop = scrollTop/scale
                }
                if(scrollTop > 400) {
                    this.backShow = true
                    // this.$refs.back.style.transform = 'scale(' + scale + ')'
                    // this.$refs.back.style.transformOrigin = 'right bottom'
                }else {
                    this.backShow = false
                }
            },
            backClick() {
                const that = this
                let timer = setInterval(() => {
                    let ispeed = Math.floor(-that.scrollTop / 5)
                    document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
                    if (that.scrollTop === 0) {
                        clearInterval(timer)
                    }
                }, 16)
            },
            setScale() {
                var width = document.documentElement.clientWidth;
                let scale = 1
                if(width < 1800) {
                    scale = width/1800
                    scale = scale.toFixed(3)
                    document.getElementsByTagName('body')[0].style.zoom = scale
                    // this.$refs.main.style.transform = 'scale(' + scale + ')'
                    // this.$refs.main.style.transformOrigin = '0 0'
                    // this.$refs.main.style.width = '1400px'
                }else {
                    document.getElementsByTagName('body')[0].style.zoom = 1
                    // this.$refs.main.style = ''
                }
            },
        }
    }
</script>
<style lang="less">
// .application-container {
//     background: url('../image/home/bg.jpg') center center no-repeat;
//     background-size: 100% 100%;
// }
    .back-top {
        position: fixed;
        right: 0%;
        // top: 0;
        bottom: 10%;
        // bottom: 1000px;
        cursor: pointer;
        width: 130px;
        height: 40px;
        text-align: center;
        background-color: #ff6720;
        color: #fff;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 20px;
            margin-right: 10px;
        }
        // border-radius: 8px;
    }
    // 隐藏banner视频播放条
    video::-webkit-media-controls{ 
        display:none !important;
    } 
    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0,0,0,0);
        z-index: 9;
    }
    .bannar-info {
        z-index: 99;
        overflow: hidden;
        .banner-content {
            padding: 0 90px;
        }
        .bannar-detail {
            font-size: 24px !important;
            // cursor: pointer;
        }
    }
    video {
        object-fit: fill;
    }
    @media screen and(max-width:900px) {
        .video {
            object-fit: fill;
            width: 100%;
            height: calc((725/1920) * 100vw);
            min-height: 500px;
            vertical-align: bottom;
        }
        .next-activity {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            .left {
                font-size: 16px;
                color: #666;
                display: flex;
                align-items: center;
                span {
                    margin-right: 20px;
                }
                span:last-child {
                    cursor: pointer;
                }
            }
            .right {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #666;
                img {
                    height: 42px;
                    width: 42px;
                    margin-left: 28px;
                }
            }
        }
        .bannar {
            .bannar-info {
                top: 10% !important;
                .bannar-detail {
                    font-size: 18px !important;
                }
            }
        }
    }
    @media screen and(min-width:900px) {
        .video {
            object-fit: fill;
            width: 100%;
            // height: 550px;
            height: calc((725/1920) * 100vw);
            min-height: 500px;
            max-height: 725px;
            vertical-align: bottom;
        }
        .next-activity {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            .left {
                font-size: 16px;
                color: #666;
                display: flex;
                align-items: center;
                span {
                    margin-right: 20px;
                }
                span:last-child {
                    cursor: pointer;
                }
            }
            .right {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #666;
                img {
                    height: 42px;
                    width: 42px;
                    margin-left: 28px;
                    cursor: pointer;
                }
                .right-wx {
                    position: relative;
                    .wxShare-close {
                        position: absolute;
                        top: 5px;
                        right: 10px;
                        cursor: pointer;
                    }
                    .right-wxShare {
                        position: absolute;
                        top: 50px;
                        left: -40px;
                        // height: 100px;
                        width: 180px;
                        background: #fff;
                        border: 1px solid #ddd;
                        padding: 20px;
                        padding-bottom: 0;
                        padding-top: 30px;
                        img {
                            height: 140px;
                            width: 140px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
</style>