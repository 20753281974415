<template>
    <div class="top-page">
        <div class="top-container">
            <header ref="header">
                <div class="padding">
                    <div class="home-top">
                        <div class="top-logo" @click="goToClick">
                            <img src="../../../image/logo.png">
                        </div>
                        <div class="top-right">
                            <el-input
                                class="search-input"
                                placeholder="搜索你想要的内容"
                                clearable
                                v-model="searchValue">
                                <el-button slot="append" @click="newsClick" icon="el-icon-search"></el-button>
                            </el-input>
                            <div class="top-right-image" @click="bgClick(0)">
                                <img src="@/image/top/dy.png" alt="">
                                <div class="image-bg">
                                    <img v-if="info.qrCodeFirst" :src="info.qrCodeFirst[0].url">
                                </div>
                            </div>
                            <div class="top-right-image" @click="bgClick(1)">
                                <img src="@/image/top/wb.png" alt="">
                                <div class="image-bg">
                                    <img v-if="info.qrCodeSecond" :src="info.qrCodeSecond[0].url">
                                </div>
                            </div>
                            <div class="top-right-image" @click="bgClick(2)">
                                <img src="@/image/top/bz.png" alt="">
                                <div class="image-bg">
                                    <img v-if="info.qrCodeThird" :src="info.qrCodeThird[0].url">
                                </div>
                            </div>
                            <div class="top-select">
                                <el-dropdown @command="handleCommand">
                                    <span class="el-dropdown-link">
                                        {{type == 0 ? '中文' : 'English'}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="0">中文</el-dropdown-item>
                                        <el-dropdown-item command="1">English</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="top-bottom">
                        <div class="top-option">
                            <div @click="menuClick(item.id)" v-for="(item,index) in menuList" :key="index" :class="menuOption == index ? 'top-option-item active' : 'top-option-item'">
                                <!-- <p>{{item}}</p> -->
                                <p>{{ $t(`menu.menu${item.id}`) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="top-overlay" v-if="overlayStatus"></div>
            </header>
        </div>
        <div class="phone-top-container">
            <header ref="header2">
                <div class="phone-padding">
                    <div class="phone-top-logo">
                        <img src="../../../image/menu.png" class="phone-menu-bg" @click="menuShow = true">
                        <img src="../../../image/logo.png" class="phone-menu-logo">
                    </div>
                    <div class="phone-menu" v-if="menuShow" >
                        <img src="../../../image/close.png" class="phone-menu-logo" @click="menuShow = false">
                        <div class="phone-menu-item" v-for="(item,index) in menuList" :key="index" @click="menuClick(item.id)">{{item.name}}</div>
                    </div>
                </div>
                <div class="top-overlay" v-if="overlayStatus"></div>
            </header>
        </div>
    </div>
</template>

<script>
    import {getBottomInfo,getBottomLink} from '../../../api/homeApi'
    export default {
        props: {
            option: {
                type: Number,
                default: 0,
            }
        },
        data() {
            return {
                searchValue: '',
                type: 0,
                // menuList: ['首页','赛道探索','赛事活动','合作开发','新闻资讯','娱乐体验','联系我们'],
                menuList: [
                    {name: '首页',id: 0},
                    {name: '赛道探索',id: 1},
                    {name: '赛事活动',id: 2},
                    {name: '合作开发',id: 3},
                    {name: '新闻资讯',id: 4},
                    {name: '娱乐体验',id: 5},
                    {name: '联系我们',id: 6},
                ],
                // menuList: [
                //     {}
                // ],
                menuOption: 0,
                menuShow: false,
                bgIdx: 0,
                bgStatus: false,
                info: {},
            }
        },
        watch: {
            "$route.path" : {
                handler(val) {
                    if(val.includes('/home')) {
                        this.menuOption = 0
                    }else if(val.includes('/explore')) {
                        this.menuOption = 1
                    }else if(val.includes('/activity')) {
                        this.menuOption = 2
                    }else if(val.includes('/news')) {
                        this.menuOption = 4
                    }else if(val.includes('/recreation')) {
                        this.menuOption = 5
                    }else if(val.includes('/contact')) {
                        this.menuOption = 6
                    }else if(val.includes('/cooperate')) {
                        this.menuOption = 3
                    }
                },
                deep: true
            }
        },
        computed: {
            overlayStatus: {
                get() {
                    return this.$store.state.common.overlayStatus
                },
            }
        },
        created() {
            let to = this.$route
            if(this.overlayStatus) {
                this.$store.commit('common/mutationOverlayStatus',false)
            }
            if(to.path.includes('/home')) {
                this.menuOption = 0
            }else if(to.path.includes('/explore')) {
                this.menuOption = 1
            }else if(to.path.includes('/activity')) {
                this.menuOption = 2
            }else if(to.path.includes('/news')) {
                this.menuOption = 4
            }else if(to.path.includes('/recreation')) {
                this.menuOption = 5
            }else if(to.path.includes('/contact')) {
                this.menuOption = 6
            }else if(to.path.includes('/cooperate')) {
                this.menuOption = 3
            }
            this.menuOption = this.option
            this.getInfo()
            // this.$nextTick(() => {
            //     if(this._isMobile()) {
            //     }else {
            //         this.setScale()
            //     }
            //     this.setScale()

            // })
            // window.addEventListener("resize", () => {
            //     if(this._isMobile()) {

            //     }else {
            //         clearTimeout(this.tid)
            //         this.tid = setTimeout(this.setScale,300)
            //     }
            // },false);
        },
        destroyed() {

        },
        methods: {
            getInfo() {
                getBottomInfo().then(res => {
                    if(res.code == 0) {
                        this.info = res.info
                    }
                })
            },
            _isMobile() {
                let flag = navigator.userAgent.match(
                /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
                );
                return flag;
            },
            handleCommand(command) {
                this.type = command
                if(command == 0) {
                    if( this.$i18n.locale == 'en') {
                        this.$i18n.locale='zh'
                    }
                }else if(command == 1) {
                    if( this.$i18n.locale == 'zh') {
                        this.$i18n.locale='en'
                    }
                }
                // this.$i18n.locale == 'zh' ? this.$i18n.locale='en':this.$i18n.locale='zh'  //设置中英文模式
                localStorage.setItem('languageSet',this.$i18n.locale)  //将用户设置存储到localStorage以便用户下次打开时使用此设置
            },
            // bgClick(val) {
            //     this.bgStatus = true
            //     this.bgIdx = val
            // },
            menuClick(val) {
                this.menuOption = val
                if(this.menuShow) {
                    this.menuShow = false
                }
                this.searchValue = ''
                if(val == 0) {
                    this.$router.push('/home')
                }else if(val == 1) {
                    this.$router.push('/explore')
                }else if(val == 2) {
                    this.$router.push('/activity')
                }else if(val == 4) {
                    this.$router.push('/news')
                }else if(val == 5) {
                    this.$router.push('/recreation')
                }else if(val == 6) {
                    this.$router.push('/contact')
                }else if(val == 3) {
                    this.$router.push('/cooperate')
                }
                // if()
            },
            setScale() {
                var width = document.documentElement.clientWidth;
                let scale = 1
                if(width < 1400) {
                    scale = width/1400
                    scale = scale.toFixed(3)
                    if(width > 900) {
                        this.$refs.header.style.transform = 'scale(' + scale + ')'
                        this.$refs.header.style.transformOrigin = '0 0'
                        this.$refs.header.style.width = '1400px'
                    }else {
                        this.$refs.header2.style.transform = 'scale(' + scale + ')'
                        this.$refs.header2.style.transformOrigin = '0 0'
                        this.$refs.header2.style.width = '1400px'
                    }
                    
                }else {
                    this.$refs.header.style = ''
                    this.$refs.header2.style = ''
                }
            },
            goToClick() {
                this.$router.push('/home')
                this.searchValue = ''
            },
            newsClick() {
                this.$router.push({path:'/news-search',query:{value: this.searchValue}})
                // this.searchValue = ''
            },
        }    
    }
</script>
<style lang="less" scoped>
@media screen and (max-width:900px) {
    .phone-top-container {
        height: 0px;
        position: fixed;
        top: 0;
        z-index: 9999;
        width: 100%;
        background: #000;
        left: 0;
    }
    header {
        height: 80px;
    }
    .phone-padding {
        position: absolute;
        top: 0;
        width: 100%;
        background: #000;
        height: 80px;
    }
    .phone-top-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        margin: 0 20px;
        .phone-menu-bg {
            height: 40px;
        }
        .phone-menu-logo {
            height: 40px;
        }
    }
    
    .top-container {
        display: none;
    }
    .phone-menu {
        height: 100vh;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.5);
        .phone-menu-logo {
            height: 60px;
            padding: 10px 20px;
        }
        .phone-menu-item {
            // width: 100%;
            color: #fff;
            padding: 20px 20px;
            font-size: 24px;
            margin-left: 60px;
        }
    }
}
@media screen and (min-width:900px) {
    .phone-top-container {
        display: none;
        // height: 60px;
        // background-color: #000;
    }
    header {
        padding-top: 60px;

    }
    
}
.top-page {
    .top-container {
        position: fixed;
        top: 0;
        z-index: 99;
        height: 0;
        width: 100%;
        background: #000;
        left: 0;
        // padding-bottom: 60px;
    }
}
     header {
            // padding-top: 1rem;
            box-sizing: border-box;
            background: #000;
            // width: 1920px;
            width: 100%;
            position: relative;
            .padding {
                position: absolute;
                top: 0;
                width: 100%;
                // background: #000;
            }
            .home-top {
                height: .6rem;
                background: #000;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                // max-width: 12rem;
                // width: 1200px;
                width: 12rem;
                margin: 0 auto;
                z-index: 99;
                .top-logo {
                    width: 1.5rem;
                    height: .32rem;
                    cursor: pointer;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                    // background: #fff;
                }
                .top-right {
                    display: flex;
                    align-items: center;
                    .search-input {
                        margin-right: .2rem;
                        height: .32rem;
                        width: 2rem;
                        background-color: rgba(255,255,255,.2);
                        .el-input__inner {
                            background: transparent;
                            border: 1px solid rgba(255,255,255,.2);
                            height: 100%;
                            font-size: .12rem;
                            color: #fff;
                            border-right: none;
                        }
                        .el-input__icon {
                            line-height: .32rem;
                        }
                        .el-input-group__append {
                            background-color: transparent;
                            border: 1px solid rgba(255,255,255,.2);
                            border-left: none;
                            padding: 0 10px;
                        }
                    }
                    .top-right-image {
                        flex-shrink: 0;
                        display: flex;
                        align-content: center;
                        position: relative;
                        img {
                            width: .24rem;
                            height: .24rem;
                            border-radius: 50%;
                            // background: #fff;
                            margin-right: .12rem;
                        }
                        .image-bg {
                            position: absolute;
                            display: none;
                            top: 40px;
                            left: -40px;
                            width: 100px;
                            height: 100px;
                            background-color: #000;
                            z-index: 99;
                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 0;
                            }
                        }
                    }
                    .top-right-image:hover {
                        .image-bg {
                            display: block;
                        }
                    }
                    .top-select {
                        .el-dropdown-link {
                            white-space: nowrap;
                            color: #fff;
                            cursor: pointer;
                        }
                        /deep/.el-input__inner {
                            background: none;
                            border: none;
                            color: #fff;
                            padding: 0;
                        }
                    }
                }
            }
            .top-bottom {
                background: rgba(0,0,0,.5);
            }
            .top-option {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 12rem;
                margin: 0 auto;
                z-index: 99;
                .top-option-item {
                    color: #fff;
                    padding: 0rem .2rem;
                    margin: 0 .25rem;
                    transform: skew(-20deg);
                    cursor: pointer;
                    box-sizing: border-box;
                    height: .38rem;
                    line-height: .38rem;
                    font-size: .16rem;
                    p {
                        transform: skew(20deg);
                        margin: 0;
                    }
                }
                .top-option-item:hover {
                    color: #EE6A23;
                }
                .active {
                    background-color: #EE6A23;
                }
                // .active:hover {
                //     color: #fff;
                // }
            }
            .top-bannar {
                width: 100%;
                height: 5.5rem;
                background: #666;
            }
        }
        .top-overlay {
            position: absolute;
            top: 0px;
            height: 98px;
            width: 100%;
            background-color: rgba(0,0,0,.6);
        }
    
</style>
<style lang="less">
    .search-input {
        margin-right: .2rem;
        height: .32rem;
        width: 2rem;
        background-color: rgba(255,255,255,.2);
        border-radius: 20px;
        .el-input__inner {
            background: transparent;
            border: 1px solid rgba(255,255,255,.2);
            height: 100%;
            font-size: .12rem;
            color: #fff;
            border-right: none;
            outline: none;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        .el-input__inner:focus {
            border-color: rgba(255,255,255,.2);
        }
        .el-input__inner:hover {
            border-color: rgba(255,255,255,.2);
        }
        .el-input__icon {
            line-height: .32rem;
        }
        .el-input-group__append {
            background-color: transparent;
            border: 1px solid rgba(255,255,255,.2);
            border-left: none;
            padding: 0 10px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
</style>