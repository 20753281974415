<template>
    <div class="recreation-page">
        <!-- <top-box :option="4"></top-box> -->
        <div class="bannar">
            <img :src="bannerSrc" v-if="bannerType">
            <video v-else muted="muted" id="startvideo" class="video" :src="bannerSrc" type="video/mp4"  autoplay="autoplay" controls="controls" loop="-1">
                <p>你的浏览器不支持video标签.</p>
            </video>
            <div class="overlay"></div>
            <div class="bannar-info">
                <div class="banner-content">
                    <div class="bannar-title">娱乐体验</div>
                    <div class="bannar-detail">{{bannerTitle}}</div>
                </div>
                <img class="banner-img" src="../../image/top/banner4.png">
            </div>
        </div>
        <main>
            <div v-for="(item,index) in dataList" :key="index" class="main-item">
                <!-- <img src="https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/77be7dee7f5944e492c18c8d9cd15bb4_mergeImage.png"> -->
                <div class="main-item-img">
                    <img :src="item.imgSrc" :title="item.name">
                </div>
                <div class="main-item-info">
                    <div class="left">
                        {{item.sketch}}
                        <!-- 这里是泛娱乐内容描述这里是泛娱乐内容描述这里是泛娱乐内容描述，这里是泛娱乐内容描述这里是泛娱乐内容描述这里是泛娱乐内容描述这里是泛娱乐内容描述，这里是泛娱乐内容描述这里是泛娱乐内容描述这里是泛娱乐内容描述，这里是泛娱乐内容描述这里是泛娱乐内容描述。 -->
                    </div>
                    <div class="right">
                        <div class="title">{{item.name}}</div>
                        <div class="detail" @click="detailsClick(item.id)">了解更多</div>
                    </div>
                </div>
            </div>
            <div class="empty" v-if="dataList.length == 0">
                <div class="empty-bg"></div>
                <div class="empty-content">暂无数据~</div>
            </div>
        </main>
        <!-- <bottom-box></bottom-box> -->
    </div>
</template>
<script>
import {getBanner,getExperience} from '../../api/homeApi'
export default {
    data() {
        return {
            bannerSrc: '',
            bannerType: 1, //1.图片 0.视频
            bannerTitle: '',
            dataList: [],
        }
    },
    created() {
        this.getBanner().then(() => {
            let myvideo = document.getElementById('startvideo');
            let bgBox = document.getElementsByClassName('banner-img')[0];
            if(myvideo) {
                myvideo.addEventListener('timeupdate', () => {
                    var playTime
                    playTime = Math.floor(myvideo.currentTime)
                    if(playTime >= Math.floor(myvideo.duration) ) {
                        bgBox.style.opacity = 0.6
                        bgBox.style.transition = ''
                    }else {
                        if(playTime == 0) {
                            if(!bgBox.style.transition) {
                                bgBox.style.opacity = 0
                                bgBox.style.transition = '4s all'
                            }
                        }
                    }
                });
                
            }
        })
        this.getExperience()
    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(
            /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
            );
            return flag;
        },
        async getBanner() {
            await getBanner({
                plate: 4,
            }).then(res => {
                if(res.code == 0) {
                    if(res.infos.length > 0) {
                        if(this._isMobile()) {
                            this.bannerType = 1
                            this.bannerSrc = JSON.parse(res.infos[0].mobileImage)[0].url
                        }else {
                            let arr = JSON.parse(res.infos[0].image)
                            if([
                                'image/png',    // .png
                                'image/jpeg',   // .jpg .jpeg
                                'image/x-ms-bmp', // .bmp
                                'image/gif',    // .gif
                                'image/tiff',   // .tif .tiff
                            ].includes(arr[0].type)) {
                                this.bannerType = 1
                            }else {
                                this.bannerType = 0
                            }
                            this.bannerSrc = JSON.parse(res.infos[0].image)[0].url
                        }
                        this.bannerTitle = res.infos[0].content
                    }
                }
            })
        },
        getExperience() {
            getExperience({
                page: 1,
                limit: 4,
            }).then(res => {
                if(res.code == 0) {
                    this.dataList = res.page.list.map(item => {
                        item.imgSrc = JSON.parse(item.image)[0].url
                        return item
                    })
                }
            })
        },
        detailsClick(id) {
            this.$router.push({path:`/recreation-details/${id}`})
        }
    },
}
</script>
<style lang="less" scoped>

    .recreation-page {
        background: url('../../image/home/bg.jpg') center center no-repeat;
        .bannar {
            width: 100%;
            height: 100%;
            // height: 5.5rem;
            background: #666;
            position: relative;
            img {
                vertical-align: middle;
                height: calc((725/1920) * 100vw);
                min-height: 500px;
                max-height: 725px;
                width: 100%;
            }
            .bannar-info {
                position: absolute;
                top: 0%;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                z-index: 9;
                .banner-img {
                    height: 100%;
                    // width: 1820px;
                    position: absolute;
                    // padding-right: 100px;
                    top: 0;
                    left: -240px;
                    opacity: .63;
                }
                .banner-content {
                    position: absolute;
                    z-index: 9;
                    padding-left: 90px;
                    top: 55%;
                    text-align: left;
                }
                .bannar-title {
                    font-size: 42px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .bannar-detail {
                    font-size: 28px;
                    color: #fff;
                    // cursor: pointer;
                }
            }
        }
        main {
            box-sizing: border-box;
            width:1600px;
            padding: .8rem 0rem;
            // background-color: #fff;
            margin: 0 auto;
            .main-item:nth-last-child(1) {
                margin-bottom: .15rem;
            }
            .main-item {
                margin-bottom: .95rem;
                .main-item-img {
                    width: 100%;
                    height: 4.6rem;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 4.6rem;
                        display: block;
                        transition: .5s all;
                    }
                    img:hover {
                        transition: .5s all;
                        transform: scale(1.05);
                    }
                }
                .main-item-info {
                    display: flex;
                    justify-content: space-between;
                    padding-top: .3rem;
                    .left {
                        width: 6rem;
                        line-height: 2;
                        color: #666;
                        font-size: .16rem;
                    }
                    .right {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        .title {
                            font-size: .24rem;
                            color: #333;
                            margin-bottom: .3rem;
                        }
                        .detail {
                            cursor: pointer;
                            font-size: .18rem;
                            color: #333;
                            padding: 10px .3rem;
                            position: relative;
                            // height: .26rem;
                            // line-height: .26rem;
                            width: 1.4rem;
                            border-left: 2px solid #EE6A23;
                            border-right: 2px solid #EE6A23;
                            border-top: 2px solid transparent;
                            border-bottom: 2px solid transparent;
                            transition: .5s all;
                        }
                        .detail:hover {
                            border-top: 2px solid #EE6A23;
                            border-bottom: 2px solid #EE6A23;
                        }
                        // .detail::after {
                        //     content: '';
                        //     position: absolute;
                        //     height: .5rem;
                        //     width: .02rem;
                        //     background-color: #EE6A23;
                        //     left: 0;
                        //     top: -.12rem;
                        // }
                        // .detail::before {
                        //     content: '';
                        //     position: absolute;
                        //     height: .5rem;
                        //     width: .02rem;
                        //     background-color: #EE6A23;
                        //     right: 0;
                        //     top: -.12rem;
                        // }
                    }
                }
            }
        }
    }
    
</style>