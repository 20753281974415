import Ajax from './ajax'

const getBanner = (params) => {
    return Ajax({
        url: '/website/tianfu/banner',
        params: params
    })
}

const getActivity = (data) => {
    return Ajax({
        url: '/website/tianfu/activity',
        data: data,
		method: 'POST'
    })
}

const getActivityDetails = (params) => {
    return Ajax({
        url: '/website/tianfu/activity/info',
        params: params
    })
}

const getNew = (data) => {
    return Ajax({
        url: '/website/tianfu/news',
        data: data,
		method: 'POST'
    })
}

const getNewsInfo = (params) => {
    return Ajax({
        url: '/website/tianfu/news/info',
        params: params
    })
}

const getProject = (data) => {
    return Ajax({
        url: '/website/tianfu/projects',
        data: data,
		method: 'POST'
    })
}

const getBrand = (data) => {
    return Ajax({
        url: '/website/tianfu/brands',
        data: data,
		method: 'POST'
    })
}

const getOur = () => {
    return Ajax({
        url: '/website/tianfu/about/us'
    })
}

const getContact = (data) => {
    return Ajax({
        url: '/website/tianfu/feedback',
        data: data,
		method: 'POST'
    })
}

const getBusiness = () => {
    return Ajax({
        url: '/website/tianfu/business'
    })
}

const getBusinessInfo = (params) => {
    return Ajax({
        url: '/website/tianfu/business/info',
        params: params,
    })
}

const getTrack = (data) => {
    return Ajax({
        url: '/website/tianfu/track',
        data: data,
		method: 'POST'
    })
}

const getTrackInfo = (params) => {
    return Ajax({
        url: '/website/tianfu/track/info',
        params: params,
    })
}

const getExperience = (data) => {
    return Ajax({
        url: '/website/tianfu/experience',
        data: data,
		method: 'POST'
    })
}

const getExperienceInfo = (params) => {
    return Ajax({
        url: '/website/tianfu/experience/info',
        params: params
    })
}


const getCategory = (params) => {
    return Ajax({
        url: '/website/tianfu/news/category',
        params: params
    })
}

const getBottomInfo = () => {
    return Ajax({
        url: '/website/tianfu/bottom'
    })
}

const getBottomLink = () => {
    return Ajax({
        url: '/website/tianfu/link'
    })
}

const getNextActivity = (params) => {
    return Ajax({
        url: '/website/tianfu/activity/next',
        params: params
    })
}

const getVideos = (data) => {
    return Ajax({
        url: '/website/tianfu/videos',
        data: data,
		method: 'POST'
    })
}

const getProjectDetails = (params) => {
    return Ajax({
        url: '/website/tianfu/project/info',
        params: params
    })
}

const getCooperation = (data) => {
    return Ajax({
        url: '/website/tianfu/cooperate',
        data: data,
        method: 'POST'
    })
}

const getCooperationDetail = (params) => {
    return Ajax({
        url: '/website/tianfu/cooperate/info',
        params: params
    })
}
export {
    getBanner,
    getActivity,
    getNew,
    getProject,
    getBrand,
    getOur,
    getContact,
    getBusiness,
    getBusinessInfo,
    getTrack,
    getTrackInfo,
    getExperience,
    getExperienceInfo,
    getNewsInfo,
    getCategory,
    getActivityDetails,
    getBottomInfo,
    getBottomLink,
    getNextActivity,
    getVideos,
    getProjectDetails,
    getCooperation,
    getCooperationDetail
}